@-webkit-keyframes dot-wave {

  0%,
  40%,
  100% {
    transform: translate(0);
    -webkit-transform: translate(0);
  }

  20% {
    transform: translate(0px, -5px);
    -webkit-transform: translate(0px, -5px);
  }
}

@keyframes dot-wave {

  0%,
  40%,
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  20% {
    -webkit-transform: translate(0px, -5px);
    transform: translate(0px, -5px);
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }

  50% {
    box-shadow: 0 0 0 20px transparent;
    -webkit-box-shadow: 0 0 0 20px transparent;
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
    -webkit-box-shadow: 0 0 0 0 transparent;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
  }

  50% {
    box-shadow: 0 0 0 20px transparent;
    -webkit-box-shadow: 0 0 0 20px transparent;
    -moz-box-shadow: 0 0 0 20px transparent;
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 0 transparent;
    -webkit-box-shadow: 0 0 0 0 transparent;
  }
}

@-webkit-keyframes conversation-appear {
  from {
    opacity: 0;
    transform: translate(0px, 2px);
    -webkit-transform: translate(0px, 2px);
  }

  to {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
  }
}

@keyframes conversation-appear {
  from {
    opacity: 0;
    transform: translate(0px, 2px);
    -webkit-transform: translate(0px, 2px);
  }

  to {
    opacity: 1;
    transform: translate(0);
    -webkit-transform: translate(0);
  }
}

.chat-section-wrapper {
  height: 100%;
}

.hide-element, .svgs {
  display: none;
}

.icon {
  fill: none;
  stroke: currentColor;
  stroke-width: 0.4em;
  vertical-align: middle;
}

.chat-header-background {
  color: #fff;
  height: 70px;
  position: relative;
  background: #F0F2F5;
  border-radius: 8px 8px 0 0;
  transition: border-radius 0.5s;
}

.chat-header-background .chat-header-tray-toggle {
  position: absolute;
  top: 28px;
  left: 3%;
  cursor: pointer;
  font-size: 13px;
  color: #1B7C53;
  font-weight: 600;
}

.chat-header-background .chat-header-tray-toggle-mobile {
  position: absolute;
  top: 48px;
  left: 20px;
  cursor: pointer;
  font-size: 13px;
  color: #1B7C53;
  font-weight: 600;
}

.chat-header-background .chat-header-close-mobile {
  position: absolute;
  top: 28px;
  right: 20px;
  cursor: pointer;
  font-size: 13px;
  color: #323232;
  font-weight: 600;
}

.chat-header-background .chat-header-close-mobile .chat-close-icon {
  width: 10px;
  height: 20px;
  margin-left: 2px;
  display: inline-block;
  color: #323232;
}

.chat-header-background .ask-hana {
  position: absolute;
  top: 20px;
  right: 3%;
  font-size: 28px;
  color: #323232;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.chat-header-background .ask-hana-mobile {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 28px;
  color: #323232;
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.chat-tray-open-header {
  border-radius: 0 8px 0 0 !important;
}

.chat-header {
  background: #1B7C53;
  color: #fff;
  height: 40px;
  position: relative;
}

.chat-header .chat-header-tray-toggle {
  display: none;
}

.chat-header .chat-title-container {
  position: absolute;
  top: 0;
  left: 12%;
  right: 12%;
  opacity: 1;
}

.chat-header .chat-title-container .screen-title-wrapper {
  padding: 16px 0px 0px 0px;
}

.chat-header .chat-title-container .screen-title-wrapper .title {
  color: #ffffff;
  font-size: 16px;
  line-height: 1.1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  display: block;
}

.chat-header .chat-splash-screen {
  opacity: 0;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}

.chat-header .chat-splash-screen .headline {
  font-size: 28px;
  line-height: 1.21;
  text-align: center;
}

.chat-header .chat-splash-screen .icon-container {
  animation: pulse 2s infinite;
  background-image: radial-gradient(circle at 50% 50%, #1B7C53, #1B7C53);
  border-radius: 50%;
  max-height: 200px;
  margin: 30px auto 20px;
  -webkit-animation: pulse 2s infinite;
  max-width: 200px;
  background-image: url("../images/HanaSplashIcon.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.chat-header .chat-splash-screen .icon-container svg {
  width: 100%;
  height: 100%;
}

.header-inapp .chat-header .chat-header-icon,
.header-inapp .chat-header .title {
  display: none !important;
}

.header-inapp .chat-body {
  height: calc(100% - 70px - 29px);
}

.splash-screen-enable .header-inapp .chat-body {
  height: calc(100% - 62% - 29px) !important;
}

.chat-bot-container .chat-window {
  border-radius: 8px;
}

.chat-bot-container .chat-window-inapp {
  border-radius: 0 0 8px 8px;
}

.quick-reply-wrapper {
  text-align: center;
  margin-left: 14px;
}

.quick-reply {
  display: block;
  background-color: #1B7C53;
  border-radius: 20px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  overflow-x: visible;
  overflow-y: visible;
  padding-left: 13px;
  padding-right: 13px;
  touch-action: manipulation;
  min-width: 35px;
  text-align: center;
  border: 1px solid #1B7C53;
  width: 89%;
  height: auto;
  padding: 8px;
}

.tnps-button {
  display: inline-block;
  width: auto;
  margin-right: 16px;
}

.chat-footer {
  border-top: 1px solid #E1E5E9;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
}

.chat-footer .urgentInfoMarquee {
  color: red;
  padding: 4px 0;
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: -24px;
}

.chat-footer .urgentInfoMarquee span {
  display: inline-block;
  padding-left: 100%;
  animation: urgentInfoMarquee 12s linear infinite;
  -webkit-animation: urgentInfoMarquee 12s linear infinite;
}

.chat-footer .urgentInfoMarquee span a {
  text-decoration: underline;
}

.chat-footer .urgentInfoMarquee span:hover {
  animation-play-state: paused;
  -webkit-animation-play-state: paused;
}

@keyframes urgentInfoMarquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

@-webkit-keyframes urgentInfoMarquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.chat-footer .input-length {
  font-size: 13px;
  color: #333333;
  height: 21px;
  text-align: left;
  background-color: #ffffff;
  margin-left: 4%;
  position: absolute;
  bottom: 10px;
}

.chat-footer .chat-input {
  display: block;
  border: none;
  font-size: 16px;
  height: 32px;
  position: relative;
  width: 88%;
  left: 4%;
  top: 6px;
  padding: 4px 5px 4px 10px;
  color: #666666;
  box-sizing: border-box;
  line-height: 20px;
  resize: none;
  overflow: hidden;
  padding-left: 8px;
  margin-bottom: 6px;
}

.chat-footer .chat-input.touched {
  padding-right: 30px;
}

.chat-footer .chat-input:focus {
  outline: none;
  background-color: #fff;
}

.chat-footer textarea::placeholder {
  text-align: left;
}

.chat-footer textarea::-webkit-input-placeholder {
  text-align: left;
}

.chat-footer textarea::-moz-placeholder {
  text-align: left;
}

.chat-footer textarea:-ms-input-placeholder {
  text-align: left;
}

.chat-footer textarea::-moz-selection {
  background-color: #b3d4fc;
}

.chat-footer textarea::selection {
  background-color: #b3d4fc;
}

.chat-footer textarea:-ms-clear {
  display: none;
}

.chat-footer textarea::-ms-clear {
  display: none;
}

.chat-footer textarea[disabled],
.chat-footer textarea[readonly] {
  background-color: #ffffff;
  opacity: 0.3;
  cursor: not-allowed;
}

.chat-footer .chat-buttons-right {
  position: absolute;
  bottom: 12px;
  right: 4%;
}

.chat-footer .chat-buttons-right .chat-submit-button {
  display: inline-block;
  cursor: pointer;
  height: 40px;
  border-radius: 22px;
  padding: 4px;
  color: #fff;
  width: 80px;
  background-color: #1B7C53;
  font-size: 16px;
  border: none;
}

.chat-footer .chat-footer-bottom {
  position: relative;
  border-radius: 0 0 8px 8px;
  transition: border-radius 0.5s;
}

.chat-footer .chat-footer-bottom .footer-left-bottom {
  position: absolute;
  bottom: 10px;
  left: 13%;
  color: #fff;
  cursor: pointer;
}

.chat-footer .chat-footer-bottom .footer-right-bottom {
  position: absolute;
  bottom: 10px;
  right: 13%;
  color: #fff;
  cursor: pointer;
}

.chat-footer .chat-tray-open-footer {
  border-radius: 0 0 8px 0 !important;
}

.chat-action-container {
  bottom: 0px;
  position: fixed;
  right: 0px;
  z-index: 999;
}

.chat-action-container .chat-open-icon {
  bottom: 20px;
  color: #ffffff;
  cursor: pointer;
  height: 82px;
  line-height: 0;
  position: absolute;
  right: 12px;
  max-width: none;
  opacity: 1;
  background-image: url("../images/HanaOpenIcon.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  margin: 0;
}

.chat-action-container .chat-close-icon {
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 70px;
  width: 70px;
  right: 30px;
  bottom: 20px;
  color: #ffffff;
  cursor: pointer;
  line-height: 0;
  position: absolute;
  max-width: none;
  background-image: url("../images/HanaCloseIcon.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
}

.chat-enabled {
  height: 300px;
  width: 300px;
  outline: none;
  background-image: radial-gradient(circle at bottom right, rgba(29, 39, 54, 0.3) 0, rgba(29, 39, 54, 0) 60%);
}

.chat-enabled .chat-open-icon {
  opacity: 0;
}

.chat-enabled .chat-close-icon {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: transform 0.2s linear, opacity 0.1s linear;
  -webkit-transition: transform 0.2s linear, opacity 0.1s linear;
}

.chat-enabled:active {
  background-image: transparent;
}

.chat-message-wrapper-user {
  animation: conversation-appear 0.25s ease;
  float: right;
  position: relative;
  padding-bottom: 10px;
  width: 75%;
  -webkit-animation: conversation-appear 0.25s ease;
}

.chat-message-wrapper-user .chat-bubble-wrapper {
  color: #fff;
  background-color: #323232;
  border-radius: 10px 10px 0 10px;
  float: right;
  padding: 6px 12px;
  position: relative;
}

.chat-message-wrapper-user .chat-bubble-wrapper .chat-message-text {
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  line-height: 20px;
}

.chat-bubble-typing-wrapper {
  height: 33px;
  width: 68px;
  padding: 0;
  background: #E1E5E9;
  border-radius: 10px;
  position: relative;
}

.chat-bubble-typing-wrapper div {
  width: 9.1px;
  height: 9px;
  border-radius: 100%;
  background-color: #c6c6c6;
  position: absolute;
  top: 12px;
  -webkit-animation: dot-wave 1s infinite ease-in;
  animation: dot-wave 1s infinite ease-in;
}

.chat-bubble-typing-wrapper div:nth-child(1) {
  left: 15px;
  background-color: #ebebeb;
}

.chat-bubble-typing-wrapper div:nth-child(2) {
  left: 30px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  background-color: #e0e0e0;
}

.chat-bubble-typing-wrapper div:nth-child(3) {
  left: 45px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  background-color: #cccccc;
}

.survey-wrapper.chat-message-wrapper-bot {
  padding-left: 0px;
}

.survey-wrapper .chat-bubble-wrapper {
  background-color: #ffffff !important;
  border: 1px solid #dddddd;
  padding: 0 12px !important;
}

.survey-wrapper .survey-prompt {
  position: absolute;
  bottom: 8px;
  text-align: center !important;
  width: 320px;
}

.survey-wrapper .survey {
  text-align: center;
}

.survey-wrapper .buttonArc {
  fill: #ebebeb;
  stroke: #d6d5d5;
  cursor: pointer;
}

.survey-wrapper .buttonArc_hover {
  fill: #cccccc;
}

.survey-wrapper .labelText {
  fill: #6b6b6b;
  font-size: 13px;
  cursor: pointer;
}

.survey-wrapper #svgIconText {
  fill: #6b6b6b;
  font-size: 13px;
}

.survey-wrapper .inactive-survey {
  pointer-events: none;
  cursor: not-allowed;
}

.chat-message-wrapper-bot,
.chat-message-wrapper-livechat {
  animation: conversation-appear 0.25s ease;
  float: left;
  padding-left: 40px;
  width: 86%;
  margin-bottom: 10px;
  position: relative;
  -webkit-animation: conversation-appear 0.25s ease;
}

.chat-message-wrapper-bot .chat-bubble-wrapper,
.chat-message-wrapper-livechat .chat-bubble-wrapper {
  background-color: #E1E5E9;
  padding: 6px 12px;
  border-radius: 0 10px 10px 10px;
  position: relative;
  display: inline-block;
  line-height: 20px;
}

.chat-message-wrapper-bot .chat-bubble-wrapper .chat-message-text,
.chat-message-wrapper-livechat .chat-bubble-wrapper .chat-message-text {
  word-break: break-word;
  white-space: pre-wrap;
  font-size: 14px;
  margin-top: 0 !important;
}

.chat-message-wrapper-bot .chat-bubble-wrapper .chat-message-text p,
.chat-message-wrapper-livechat .chat-bubble-wrapper .chat-message-text p {
  margin: 0;
  display: contents;
}

.chat-message-wrapper-bot .chat-bubble-wrapper .chat-message-text ul,
.chat-message-wrapper-livechat .chat-bubble-wrapper .chat-message-text ul {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.chat-message-wrapper-bot .chat-bubble-wrapper a,
.chat-message-wrapper-livechat .chat-bubble-wrapper a {
  text-decoration: underline;
  text-decoration-color: #323232;
  color: #323232;
}

.chat-message-wrapper-bot .chat-bubble-wrapper a:hover,
.chat-message-wrapper-livechat .chat-bubble-wrapper a:hover {
  text-decoration-color: #00A45F;
  color: #00A45F;
}

.chat-message-wrapper-bot .bot-avatar-wrapper,
.chat-message-wrapper-livechat .bot-avatar-wrapper,
.chat-message-wrapper-bot .livechat-avatar-wrapper,
.chat-message-wrapper-livechat .livechat-avatar-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.bot-avatar-wrapper .small-hana-icon {
  background-image: url("../images/HanaSmallIcon.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  margin: 0;
}

.bot-avatar-wrapper .live-chat-icon {
  background-image: url("../images/HanaLiveChatIcon.png");
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  margin: 0;
}

.chat-message-wrapper-bot .livechat-avatar-wrapper,
.chat-message-wrapper-livechat .livechat-avatar-wrapper {
  padding: 2px;
}

.chat-message-wrapper-bot .groupmsg,
.chat-message-wrapper-livechat .groupmsg {
  display: none;
}

.chat-message-wrapper-bot .richmedia>span:first-child,
.chat-message-wrapper-livechat .richmedia>span:first-child,
.chat-message-wrapper-bot .richmedia div:first-child,
.chat-message-wrapper-livechat .richmedia div:first-child {
  margin-top: 10px;
}

.chat-message-wrapper-bot .richmedia .single-carousel-item,
.chat-message-wrapper-livechat .richmedia .single-carousel-item {
  width: auto;
}

.chat-message-wrapper-bot .richmedia .multiple-carousel-item,
.chat-message-wrapper-livechat .richmedia .multiple-carousel-item {
  width: 500px;
  margin-top: -6px !important;
  margin-bottom: -18px;
  position: relative;
}

.chat-message-wrapper-bot .richmedia .multiple-carousel-item .mask,
.chat-message-wrapper-livechat .richmedia .multiple-carousel-item .mask {
  width: 78px;
  height: 90%;
  position: absolute;
  background: linear-gradient(to right, rgba(235, 235, 235, 0), #ebebeb 65%, #ebebeb);
  z-index: 2;
  left: 250px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .chat-bubble-wrapper,
.chat-message-wrapper-livechat .richmedia .chat-carousel .chat-bubble-wrapper {
  background-color: #fff;
  border-radius: 10px;
  position: relative;
  display: inline-block;
  padding: 5px 0px;
  border: 1px solid #ccc;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .chat-bubble-wrapper a,
.chat-message-wrapper-livechat .richmedia .chat-carousel .chat-bubble-wrapper a {
  text-decoration: underline;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-footer,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-footer {
  clear: both;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-footer .links,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-footer .links {
  border-top: 1px solid #ebebeb;
  text-align: center;
  padding: 8px 0px;
  font-size: 16px;
  line-height: 1.25;
  color: #1B7C53;
  cursor: pointer;
  display: block;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-footer a,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-footer a {
  text-decoration: none;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-icon-wds,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-icon-wds {
  float: left;
  margin: 10px 12px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-icon-wds .chat-carousel-icon,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-icon-wds .chat-carousel-icon {
  width: 32px;
  height: 48px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-icon-generic,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-icon-generic {
  display: none;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds {
  float: right;
  line-height: 1.19;
  padding: 6px 0;
  width: 168px;
  margin-right: 14px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-title,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-title {
  font-size: 16px;
  margin: 2px 0;
  position: relative;
  height: 3.6em;
  line-height: 1.2;
  overflow: hidden;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-title ::before,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-title ::before {
  content: "...";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 1.1em;
  padding-left: 3px;
  box-sizing: border-box;
  background-color: white;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-title ::after,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-title ::after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: white;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-description,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-description {
  font-size: 14px;
  margin: 4px 0;
  position: relative;
  max-height: 3.6em;
  line-height: 1.2;
  overflow: hidden;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-description ::before,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-description ::before {
  content: "...";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  width: 1.1em;
  padding-left: 3px;
  box-sizing: border-box;
  background-color: white;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-wds .carousel-body-description ::after,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-wds .carousel-body-description ::after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: white;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-title,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-title {
  margin: 10px 0 0;
  padding: 6px 12px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-content,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-content {
  padding: 6px 12px 5px;
  margin: 0;
  height: 160px;
  overflow: auto;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-content span,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-content span,
.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-content div,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-content div,
.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-content p,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-content p {
  display: inline-block !important;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-description,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-description {
  padding: 6px 12px;
  margin: 0;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel-body-generic .carousel-body-description:blank,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel-body-generic .carousel-body-description:blank {
  display: none;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__slide,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__slide {
  display: inline-block !important;
  padding: 0px 5px 0px 0px !important;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__pagination,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__pagination {
  margin-top: -6px;
  width: 250px;
  text-align: center !important;
  pointer-events: none;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__pagination .carousel__page-item,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__pagination .carousel__page-item {
  display: inline;
  margin: 0 2px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__pagination .carousel__page,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__pagination .carousel__page {
  width: 6px;
  height: 6px;
  background-color: #ccc;
  display: inline-block;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__pagination .carousel__page--active::after,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__pagination .carousel__page--active::after {
  border: none;
  width: 6px;
  height: 6px;
  background-color: #666;
  left: 0;
  top: 0;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__control.carousel__control--left,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__control.carousel__control--left {
  left: -40px;
}

.chat-message-wrapper-bot .richmedia .chat-carousel .carousel__control.carousel__control--right,
.chat-message-wrapper-livechat .richmedia .chat-carousel .carousel__control.carousel__control--right {
  right: 222px;
}

.chat-message-wrapper-bot .richmedia .chat-cdtimer .timer-progress-bar,
.chat-message-wrapper-livechat .richmedia .chat-cdtimer .timer-progress-bar {
  position: relative;
  height: 3px;
  width: 230px;
  margin: 14px auto 0;
  background: #1B7C53;
}

.chat-message-wrapper-bot .richmedia .chat-cdtimer .timer-filler,
.chat-message-wrapper-livechat .richmedia .chat-cdtimer .timer-filler {
  background: #dddddd;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  margin-top: auto;
}

.chat-message-wrapper-bot .richmedia .chat-cdtimer .timer-seconds,
.chat-message-wrapper-livechat .richmedia .chat-cdtimer .timer-seconds {
  text-align: center;
}

.chat-message-wrapper-bot .richmedia .bot-avatar-wrapper,
.chat-message-wrapper-livechat .richmedia .bot-avatar-wrapper {
  position: absolute;
  left: 0;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}

.chat-message-wrapper-bot .richmedia .bot-avatar-wrapper svg,
.chat-message-wrapper-livechat .richmedia .bot-avatar-wrapper svg {
  width: 100%;
  height: 100%;
}

.chat-message-wrapper-bot .chat-thumbs {
  display: none;
}

.last-comment .chat-thumbs {
  display: inline-block;
  margin-top: 4px;
  background: none;
}

.last-comment .chat-thumbs .thumbs-wrapper {
  clear: both;
  overflow: hidden;
  margin: 0;
  padding: 3px;
  display: block !important;
  width: 240px;
}

.last-comment .chat-thumbs .thumbs-text {
  line-height: 24px;
  margin-bottom: 6px !important;
  display: block !important;
}

.last-comment .chat-thumbs .thumbs {
  float: left;
  line-height: 41px;
  border: 1px solid #5A6376;
  border-radius: 22px;
  height: 42px;
  width: 100px;
  text-align: center;
  margin-right: 16px;
  font-size: 16px;
  cursor: pointer;
}

.last-comment .chat-thumbs .thumbs svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  color: white;
  margin-right: 4px;
}

.last-comment .chat-thumbs .thumbs .thumbs-up {
  transform: scale(-1, 1);
  transform-origin: center;
  margin-top: -4px;
}

.chat-message-wrapper-notice {
  border-top: solid 1px #1B7C53;
  border-bottom: solid 1px #1B7C53;
  clear: both;
  margin-bottom: 90px;
  position: relative;
  padding: 8px 0;
  top: 10px;
}

.chat-message-wrapper-notice .chat-message-text {
  text-align: center;
  font-size: 16px;
}

.chat-message-wrapper-notice .chat-message-text span {
  text-align: center;
}

.chat-message-wrapper-notice .chat-message-text p {
  text-align: center;
  color: #666666;
  line-height: 1.43;
  font-size: 14px;
  margin: 0;
}

-wrapper {
  background: #ffffff;
  height: 100%;
  border-radius: 16px;
}

.conversation-body-container {
  bottom: 0;
  padding: 2%;
  position: relative;
  top: 0;
}

.conversation-wrapper {
  min-height: 78%;
}

.quick-action-wrapper {
  bottom: 0;
  float: none;
  clear: both;
  padding: 4px 20px;
}

.chat-body {
  position: relative;
  height: calc(100% - 70px - 99px);
}

.chat-body .chat-scroll-bar {
  background-color: #fff;
}

.chat-body .chat-scroll-bar .scroll-view {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  margin-bottom: 0px !important;
}

.chat-body .chat-scroll-bar .horizontal-scrollbar {
  display: none;
}

.chat-body .chat-scroll-bar .vertical-scrollbar {
  position: absolute;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  z-index: 3;
}

.chat-body .chat-scroll-bar .vertical-scrollbar-inner {
  background-color: #4a4a4a;
  border-radius: inherit;
}

.splash-screen-enable .chat-header {
  height: 62%;
  border-radius: 8px 8px 0 0;
}

.splash-screen-enable .chat-header .chat-splash-screen {
  height: inherit !important;
  opacity: 1;
  transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
}

.splash-screen-enable .chat-header .chat-title-container {
  height: calc(100% - 100px) !important;
}

.splash-screen-enable .chat-header .screen-title-wrapper {
  height: 100% !important;
}

.splash-screen-enable .chat-header .icon-container {
  height: 85% !important;
}

.splash-screen-enable .chat-header .headline {
  height: 30% !important;
}

.splash-screen-enable .chat-body {
  height: calc(100% - 62% - 99px);
}

.chat-window {
  position: fixed;
  opacity: 1;
  width: 100%;
  height: 100%;
  z-index: 1000;
  box-shadow: 0px 5px 10px 4px rgba(50, 50, 50, 0.15), -5px 5px 10px 4px rgba(50, 50, 50, 0.15);
  -webkit-box-shadow: 0px 5px 10px 4px rgba(50, 50, 50, 0.15), -5px 5px 10px 4px rgba(50, 50, 50, 0.15);
}

.chat-window.chat-window-enter {
  opacity: 0;
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}

.chat-window.chat-window-enter.chat-window-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  -webkit-transform: translateY(0);
  -webkit-transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

.chat-window.chat-window-exit {
  opacity: 1;
}

.chat-window.chat-window-exit.chat-window-exit-active {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  -webkit-transform: translateY(15px);
  -webkit-transition: opacity 0.3s ease-out, transform 0.3s ease-out;
}

div,
hr,
ol,
p,
ul,
span {
  display: block;
  text-align: left;
}

li {
  display: list-item;
}

@media only screen and (max-width: 767px) {
  .chat-tray-open-header {
    border-radius: 8px 8px 0 0 !important;
  }

  .chat-footer .chat-tray-open-footer {
    border-radius: 0 0 8px 8px !important;
  }

  .chat-window {
    bottom: 0 !important;
    height: 100% !important;
    max-height: 100%;
    right: 0;
    width: 100%;
  }

  .chat-enabled {
    opacity: 0;
  }

  .carousel__controls {
    display: none;
  }
}

@media only screen and (max-height: 420px) {
  .splash-screen-enable .chat-header {
    height: 50px !important;
  }

  .splash-screen-enable .chat-header .chat-splash-screen {
    opacity: 0 !important;
  }

  .splash-screen-enable .chat-body {
    height: calc(100% - 70px - 99px) !important;
  }

  .splash-screen-enable .carousel__controls {
    display: none;
  }

  .splash-screen-enable.header-inapp .chat-header {
    display: none !important;
  }

  .splash-screen-enable.header-inapp .chat-body {
    height: calc(100% - 29px) !important;
  }
}